import { getOssSts } from "@/api/tool/oss";
import { getSessionStorageObj, setSessionStorageObj } from "@/utils/db";
import { isBlank } from "@/utils/whale";
import OSS from "ali-oss";
import { Client } from "minio";

/**
 * 设置oss配置，在上传之前调用
 */
export function setOssConfig() {
  let flag = false;
  if (!isBlank(getSessionStorageObj("ossInfo"))) {
    let expirationM = new Date(
      getSessionStorageObj("ossInfo").expiration
    ).getTime();
    let currentM = new Date().getTime();
    if (currentM > expirationM) {
      flag = true;
    }
  } else {
    flag = true;
  }
  if (flag) {
    getOssSts().then(res => {
      if (res.code === 200) {
        setSessionStorageObj("ossInfo", res.data);
      }
    });
  }
}

/**
 * 全局上传组件，所有文件上传走此接口
 * @param fileName 文件名称
 * @param file     文件
 * @returns  url
 */
export async function putFile(fileName, file) {
  // console.log('上传组件第一步', fileName, file)
  let ossToken = getSessionStorageObj("ossInfo");
  if (isBlank(ossToken)) {
    throw new Error("上传功能暂时无法使用，请设置参数后使用");
  } else {
    let expirationM = new Date(ossToken.expiration).getTime();
    let currentM = new Date().getTime();
    if (currentM > expirationM) {
      throw new Error("上传功能暂时无法使用，请设置参数后使用");
    }
  }
  // console.log('判断是否走此处')

  let index = fileName.lastIndexOf(".");
  let suffix = fileName.substr(index + 1);

  let metaData = {};

  if (suffix === "jpg" || suffix === "png" || suffix === "jpeg") {
    metaData = { ContentType: "image/jpeg" };
  }
  // 如果 是阿里云oss
  if (ossToken.type === "ali") {
    return new Promise(async(resolve, reject) => {
      try {
        const aliOssClient = new OSS({
          region: "oss-cn-beijing",
          bucket: ossToken.bucketName,
          accessKeyId: ossToken.accessKeyId,
          accessKeySecret: ossToken.accessKeySecret,
          stsToken: ossToken.securityToken,
          secure: true
        });
        let result =await aliOssClient.put(fileName, file, metaData);
        resolve(result.url);
      } catch (e) {
        console.log(e);
        reject("上传异常");
      }
    });
    // 如果 是minio
  } else if (ossToken.type === "minio") {
    return new Promise((resolve, reject) => {
      try {
        const minioClient = new Client({
          endPoint: ossToken.stsEndpoint,
          port: ossToken.port,
          useSSL: ossToken.useSSL,
          accessKey: ossToken.accessKeyId,
          secretKey: ossToken.accessKeySecret,
          sessionToken: ossToken.securityToken
        });

        minioClient.on("uploadProgress",function(progress){
          console.log('progress上传进度',progress)
      })  


        let reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function(e) {
          let res = e.target.result;
          let buf = Buffer.from(res);
          result = minioClient.putObject(
            ossToken.bucketName,
            fileName,
            buf,
            metaData,
            function(err, etag) {
              if (err == null) {
                let url =
                  ossToken.transformEndpoint +
                  "/" +
                  ossToken.bucketName +
                  "/" +
                  fileName;
                resolve(url);
                console.log("上传成功");
              }
            }
          );
        };
      } catch (e) {
        console.log(e);
        reject("上传异常");
      }
    });
  }
}
